import React, { useState, useRef, useContext, useEffect } from "react";

import { SocketContext } from "../context/socket";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import ViewerWrapper from "./ViewerWrapper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

//HOOKS
import useGlobal from "../hooks/useGlobal";

import background from "../images/bg_ags.jpg";
import title from "../images/tierlist.png";

function ViewerTierlist() {
   const socket = useContext(SocketContext);

   // USE GLOBAL HOOK
   const [fonts, activeLayout, state, updateState, { subscribeTierlist }] =
      useGlobal();

   useEffect(() => {
      subscribeTierlist();
   }, []);

   useEffect(() => {
      // console.log(state);
   }, [state]);

   return (
      <ViewerWrapper key='tierlistViewer' activeLayout={activeLayout}>
         {/* <Stack
            alignItems='center'
            sx={{ position: "absolute", width: "100%" }}
         >
            <Box
               component={motion.div}
               className='titles'
               inital={{ y: 0 }}
               animate={{ y: 30 }}
            >
               <motion.img
                  src={title}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
               />
            </Box>
         </Stack> */}

         {state &&
            state.data &&
            state.data.tierlist &&
            state.data.tierlist.items && (
               <>
                  {state.data.tierlist.items.map((row, i) => (
                     <AnimatePresence>
                        {row.active && (
                           <Box
                              sx={{
                                 width: 672,
                                 height: 672,
                                 overflow: "hidden",
                                 position: "absolute",
                                 borderRadius: 2,
                              }}
                              component={motion.div}
                              key={"item" + row.rowid}
                              className='primaryImage'
                              layout
                              layoutId={"tierlistLayout" + row.rowid}
                              initial={{
                                 left: 627,
                                 top: 196,
                                 scale: 1.2,
                                 opacity: 0,
                              }}
                              animate={{
                                 scale: 1,
                                 opacity: 1,
                                 transition: {
                                    ease: "easeOut",
                                    duration: 0.5,
                                 },
                              }}
                              exit={{
                                 left: 627,
                                 top: 196,
                                 scale: 1.1,
                                 opacity: 0,
                                 transition: {
                                    ease: "easeIn",
                                    duration: 0.5,
                                 },
                              }}
                           >
                              {_(state.data.tierlistImages)
                                 .filter((x) => x.rowid == row.rowid)
                                 .value()
                                 .map((imagerow, i) => (
                                    <Box
                                       sx={{
                                          position: "absolute",
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          height: "100%",
                                       }}
                                    >
                                       <img
                                          src={imagerow.image}
                                          height='100%'
                                       />
                                    </Box>
                                 ))}
                              <span className='title'>{row.text}</span>
                           </Box>
                        )}
                     </AnimatePresence>
                  ))}

                  <Box
                     sx={{ position: "absolute", top: 150, left: 1330 }}
                     className='tierlistAll'
                  >
                     {["S", "A", "B", "C", "D", "F"].map((slot, c) => (
                        <Box
                           component={motion.div}
                           className={"slot row" + slot}
                           sx={{
                              overflow: "hidden",
                           }}
                           initial={{
                              x: 30,
                              y: -18 + c * -8,
                              opacity: 0,
                           }}
                           animate={{
                              x: 0,
                              opacity: 1,
                              transition: {
                                 delay: c * 0.1,
                                 duration: 0.2,
                              },
                           }}
                        >
                           {_(state.data.tierlist.items)
                              .filter((x) => x.slot == slot)
                              .orderBy("updated", "desc")
                              .value()
                              .map((row, i) => (
                                 <Box
                                    component={motion.div}
                                    className='item'
                                    key={"item" + row.rowid}
                                    layoutId={"tierlistLayout" + row.rowid}
                                    layout
                                 >
                                    {row.slot == slot && (
                                       <>
                                          {_(state.data.tierlistImages)
                                             .filter(
                                                (x) => x.rowid == row.rowid
                                             )
                                             .value()
                                             .map((imagerow, i) => (
                                                <>
                                                   <img src={imagerow.image} />
                                                </>
                                             ))}
                                       </>
                                    )}
                                 </Box>
                              ))}
                        </Box>
                     ))}
                  </Box>
               </>
            )}

         <Box
            className='background'
            sx={{ position: "absolute", zIndex: -1, top: 0, left: 0 }}
         >
            <img src={background} />
         </Box>
      </ViewerWrapper>
   );
}

export default ViewerTierlist;
